<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="title">
          <el-input
              v-model="query.title"
              placeholder="请输入-标题内容"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="status">
          <el-select
              v-model="query.status"
              placeholder="请选择-状态"
              style="width: 260px"
          >
            <el-option label="上架中" value="0"></el-option>
            <el-option label="已下架" value="1"></el-option>
            <el-option label="待上架" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="type">
          <el-select
              v-model="query.type"
              placeholder="请选择-业务类型"
              style="width: 260px"
          >
            <el-option label="通用券" value="0"></el-option>
            <el-option label="停车券" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="couponCode">
          <el-input
              v-model="query.couponCode"
              placeholder="发行编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="bearerId">
          <!-- <Bearer v-model="query.bearerId" style="width: 260px" /> -->
          <el-select
              v-model="query.bearerId"
              placeholder="请选择-承担方"
              style="width: 260px"
          >
            <el-option label="平台" value="0"></el-option>
            <el-option label="结算方" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="couponEventId">
          <!-- <el-select v-model="query.couponEventId" placeholder="请选择-触发事件">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select> -->
          <TriggerEvent v-model="query.couponEventId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="paySettleAccountId">
          <!-- <el-input
            v-model="query.paySettleAccountId"
            placeholder="请输入结算方名称"
          ></el-input> -->
          <Settlement v-model="query.paySettleAccountId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="parkingTypeTime">
          <div class="xxd-input__wrap">
            <el-select v-model="query.parkingTypeTime" placeholder="时间范围">
              <el-option label="上架时间" value="1"></el-option>
              <el-option label="下架时间" value="2"></el-option>
            </el-select>
            <el-date-picker
                v-model="timeValue"
                end-placeholder="结束日期"
                prefix-icon="none"
                range-separator="~"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ total }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              icon="add-select"
              title="添加套餐"
              @click.native="goAdd"
          />
          <icon-button
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="publishList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              fixed
              label="发行编号"
              prop="couponRuleCode"
              width="230"
          >
          </el-table-column>
          <el-table-column label="标题" prop="title" width="230">
          </el-table-column>

          <el-table-column label="触发事件" prop="eventName" width="230">
          </el-table-column>
          <el-table-column label="适用业务类型" prop="couponType" width="140">
            <template slot-scope="scope">
              <span>{{
                  scope.row.couponType === 0
                      ? "通用"
                      : scope.row.couponType === 1
                          ? "停车券"
                          : "洗车券"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结算方名称" prop="settlementName" width="230">
          </el-table-column>
          <el-table-column label="承担方" prop="bearerName" width="200">
          </el-table-column>
          <el-table-column label="上架时间" prop="putawayDate" width="180">
          </el-table-column>
          <el-table-column label="下架时间" prop="soldoutDate" width="180">
          </el-table-column>
          <el-table-column label="发行量" prop="publishCount">
          </el-table-column>
          <el-table-column label="领取量" prop="receviedCount">
          </el-table-column>
          <el-table-column label="使用量" prop="useNumber"></el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span>
                {{ status[scope.row.status] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              prop="name"
              width="240px"
          >
            <template slot-scope="scope">
              <el-button
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="goDetail(scope.row.id)"
              >详情
              </el-button
              >
              <el-button
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="provideCoupon(scope.row.id)"
              >发券
              </el-button
              >
              <el-button
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="offShelf(scope.row.id)"
              >下架
              </el-button
              >
              <el-button
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="downloadQrcode(scope.row)"
              >静态码下载
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="pagination.page"
              :page-size="pagination.size"
              :page-sizes="[10, 15, 30, 50]"
              :total="pagination.totalSize"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <div id="coupon_static_qrcode" style="display: none"></div>

    <!-- 发券弹窗 -->
    <el-dialog
        v-loading="isAddLoading"
        :append-to-body="true"
        :before-close="beforeAddDialogClose"
        :visible.sync="couponDialogFormVisible"
        class="common-dialog"
        title="发券"
        width="900px"
    >
      <el-form ref="model" :model="model" label-width="200px" size="small">
        <el-form-item label="领券对象">
          <el-radio v-model="model.carOrPeople" label="1">用户</el-radio>
          <el-radio v-model="model.carOrPeople" label="2">车辆</el-radio>
        </el-form-item>
        <el-form-item v-if="this.model.carOrPeople === '1'">
          <el-input
              v-model="model.phoneNumber"
              placeholder="请输入用户手机号"
              style="width: 260px"
          ></el-input>
          <el-button
              style="margin-left: 10px"
              type="primary"
              @click="addPhoneNumber"
          >添加
          </el-button
          >
        </el-form-item>
        <el-form-item v-if="this.model.carOrPeople === '2'">
          <el-select
              v-model="model.color"
              placeholder="请选择车牌颜色"
              style="margin-right: 10px"
          >
            <el-option label="黄牌" value="01"></el-option>
            <el-option label="蓝牌" value="02"></el-option>
            <el-option label="黑牌" value="06"></el-option>
            <el-option label="绿牌" value="03"></el-option>
            <el-option label="黄绿牌" value="04"></el-option>
          </el-select>
          <el-input
              v-model="model.carNumber"
              placeholder="请输入车牌号"
              style="width: 260px; margin-right: 10px"
          ></el-input>
          <span
          ><el-button type="primary" @click="addCarNumber"
          >添加</el-button
          ></span
          >
        </el-form-item>
        <el-form-item label="当前录入">
          <div class="inputBox coupon-select-target">
            <!-- <div class="phone">123<span class="delete" @click="deleteInput">x</span></div>
            <div>234</div> -->
            <div v-for="item in userList" :key="item.id" class="item user">
              {{ item.mobileNum }}
              <span class="delete" @click="deletePhone(e)">x</span>
            </div>
            <div v-for="item in autoList" :key="item.id">
              <Numplate :type="item.autoType">{{ item.plateNumber }}</Numplate>
              <span class="delete" @click="deleteCar(e)">x</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="发放原因" required>
          <el-input
              v-model="model.reason"
              :rows="2"
              placeholder="请输入发放原因"
              type="textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="saveList">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TriggerEvent from "@/components/TriggerEvent";
import Settlement from "@/components/Settlement";
// import Bearer from "@/components/Bearer";
import Numplate from "@/components/Numplate";
import {doExport} from "@/api/common";
import Permission from "@/mixins/permission";
import {
  getAutoInfoOrRegister,
  getCouponPublishRuleForPage,
  getUsersByMobileNumAndType,
  publishCoupon,
  soldOutPublishRule,
} from "@/api/coupon";
import QRCode from "qrcodejs2";

export default {
  name: "publish-manage",
  mixins: [Permission],
  data() {
    return {
      couponDialogFormVisible: false,
      isAddLoading: false,
      model: {
        carOrPeople: "2",
        phoneNumber: "",
        carNumber: "",
        color: "02",
        reason: "",
      },
      timeValue: [],
      couponRuleId: "", //发券用规则id
      userList: [], //用户列表
      autoList: [], //车牌列表
      query: {
        title: "",
        status: "",
        type: "",
        couponCode: "",
        bearerId: "",
        couponEventId: "",
        paySettleAccountId: "",
        putawayDate: "",
        soldoutDate: "",
        parkingTypeTime: "1", //上下架时间选择
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      status: {
        0: "上架中",
        1: "已下架",
        2: "待上架",
        3: "已下架",
      },
      pagination: {
        page: 1,
        size: 10,
        totalSize: 0,
        totalPages: 1,
      },
      total: 0,
      isLoading: false,
      publishList: [],
    };
  },
  components: {
    TriggerEvent,
    Settlement,
    // Bearer,
    Numplate,
  },
  methods: {
    createQrcode(content) {
      const qrcode = new QRCode("coupon_static_qrcode", {
        text: content,
        width: 240,
        height: 240,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      return qrcode;
    },
    downloadQrcode(item) {
      document.getElementById("coupon_static_qrcode").innerHTML = "";
      const url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/#/coupon_qrcode?coupon_id=" +
          item.id;
      this.createQrcode(url);
      const myCanvas = document
          .getElementById("coupon_static_qrcode")
          .getElementsByTagName("canvas");
      const img = document
          .getElementById("coupon_static_qrcode")
          .getElementsByTagName("img");

      const a = document.createElement("a");
      const imgURL = myCanvas[0].toDataURL("image/jpg");
      const ua = navigator.userAgent;
      if (ua.indexOf("Trident") !== -1 && ua.indexOf("Windows") !== -1) {
        var bstr = atob(imgURL.split(",")[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "下载" + "." + "png");
      } else if (ua.indexOf("Firefox") > -1) {
        const blob = this.base64ToBlob(imgURL);
        a.download = " "; // 如果填内容识别不到 下载为未知文件 所以我这里就不填为空
        a.href = URL.createObjectURL(blob);
        a.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
        ); // 兼容火狐
      } else {
        // 谷歌兼容下载
        img.src = myCanvas[0].toDataURL("image/jpg");
        a.href = img.src;
        a.download = "优惠券静态二维码";
        a.click();
      }
    },
    //查看发行优惠券详情
    goDetail(e) {
      this.$router.push({
        path: "/coupon_manage/publish_detail",
        query: {
          id: e,
        },
      });
    },
    goAdd() {
      this.$router.push("/coupon_manage/add_publish");
    },
    //发券
    provideCoupon(e) {
      this.couponDialogFormVisible = true;
      this.couponRuleId = e;
    },
    //弹窗取消
    cancel() {
      this.couponDialogFormVisible = false;
      this.userList = [];
      this.autoList = [];
      this.model.phoneNumber = "";
      this.model.carNumber = "";
      this.model.color = "02";
      this.model.reason = "";
    },
    //关闭发券弹窗，清理表单
    beforeAddDialogClose(done) {
      this.userList = [];
      this.autoList = [];
      this.model.phoneNumber = "";
      this.model.carNumber = "";
      this.model.color = "02";
      this.model.reason = "";
      done();
    },
    //弹窗确定  确认发券
    saveList() {
      const userList = this.userList.map((item) => {
        return item.id;
      });
      const autoList = this.autoList.map((item) => {
        return item.id;
      });
      const userIds = userList.concat(autoList);
      const data = {
        couponRuleId: this.couponRuleId,
        remark: this.model.reason,
        userIds: userIds,
      };
      if (this.model.reason === "") {
        this.$message.error("请填写发券原因");
        return false;
      }
      this.isAddLoading = true;
      publishCoupon(data).then((res) => {
        this.isAddLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message({
            message: "发放成功",
            type: "success",
          });
          this.couponDialogFormVisible = false;
          this.userList = [];
          this.autoList = [];
          this.model.reason = "";
          this.doSearch();
        }
      });
    },
    //发券弹窗删除录入的手机号和车牌号
    deleteCar(e) {
      const autoList = this.autoList;
      const id = e;
      autoList.splice(autoList.indexOf(id), 1);
    },
    deletePhone(e) {
      const userList = this.userList;
      const id = e;
      userList.splice(userList.indexOf(id), 1);
    },
    // 根据手机号查userId
    addPhoneNumber() {
      const mobile = this.model.phoneNumber;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (mobile === "") {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
        });
        return false;
      } else if (!reg.test(mobile)) {
        this.$message({
          message: "手机号格式不正确",
          type: "warning",
        });
        return false;
      }
      this.isAddLoading = true;
      getUsersByMobileNumAndType(mobile).then((res) => {
        this.isAddLoading = false;
        if (res && res.code === 30 && res.result) {
          this.userList.push(res.returnObject[0]);
          this.model.phoneNumber = "";
        }
      });
    },
    //根据车牌号查userId
    addCarNumber() {
      const numplate = this.model.carNumber;
      const color = this.model.color;
      const reg =
          /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/;
      if (numplate === "") {
        this.$message({
          message: "车牌号不能为空",
          type: "warning",
        });
        return false;
      } else if (!reg.test(numplate)) {
        this.$message({
          message: "车牌号格式不正确",
          type: "warning",
        });
        return false;
      }
      this.isAddLoading = true;
      getAutoInfoOrRegister(numplate, color).then((res) => {
        this.isAddLoading = false;
        if (res && res.code === 30 && res.result) {
          this.autoList.push(res.returnObject);
          this.model.carNumber = "";
          this.model.color = "02";
        }
      });
    },
    //下架
    offShelf(e) {
      this.$confirm("此操作将下架优惠券, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            soldOutPublishRule(e).then((res) => {
              if (res && res.code === 30 && res.result) {
                this.$message({
                  message: "下架成功",
                  type: "success",
                });
                this.doSearch();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消下架",
            });
          });
    },
    //重置查询条件
    resetForm() {
      this.$refs.query.resetFields();
      this.timeValue = [];
      this.query.putawayDate = "";
      this.query.soldoutDate = "";
      this.doSearch();
    },
    paramFormat(query) {
      const {page, size} = this.pagination;

      return Object.assign(query, {
        page,
        size,
      });
    },
    //查询列表
    doSearch() {
      this.pagination.page = 1;
      this.pagination.size = 10;
      this.search();
    },
    search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      if (this.timeValue.length > 0) {
        params["putawayDate"] = this.timeValue[0] + " 00:00:00";
        params["soldoutDate"] = this.timeValue[1] + " 23:59:59";
      }
      getCouponPublishRuleForPage(params).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.publishList = res.returnObject.records.map((item) => {
            return {
              id: item.id,
              useNumber: item.useNumber,
              putawayDate: item.putawayDate,
              couponType: item.couponType,
              couponRuleCode: item.couponRuleCode,
              eventName: item.eventName,
              title: item.title,
              soldoutDate: item.soldoutDate,
              status: item.status,
              bearerName: item.bearerName,
              settlementName: item.settlementName || "",
              publishCount: item.publishCount,
              receviedCount: item.receviedCount,
            };
          });
          this.pagination.totalSize = res.returnObject.total;
          this.pagination.totalPages = res.returnObject.pages;
          this.total = res.returnObject.total;
          this.form.exportSize = res.returnObject.total;
        }
      });
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.timeValue.length > 0) {
        params["putawayDate"] = this.timeValue[0] + " 00:00:00";
        params["soldoutDate"] = this.timeValue[1] + " 23:59:59";
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport(
          "/couponController/getCouponPublishRuleForPage",
          params,
          "优惠券发行管理.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    handleSizeChange(e) {
      this.pagination.size = e;
      this.search();
    },
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.search();
    },
  },
  mounted() {
    this.doSearch();
  },
};
</script>

<style lang="less" scoped>
.inputBox {
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  .item {
    height: 30px;
    padding: 0 7px;
    background: #ddd;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 10px;
  }
}

// .phone {
//   height: 25px;
//   line-height: 25px;
//   background: hsla(0,0%,86.7%,.4196078431372549);
//   border-radius: 3px;
//   border: 1px solid #ccc;
//   margin-right: 5px;
//   margin-top: 5px;
// }
.delete {
  cursor: pointer;
}
</style>
