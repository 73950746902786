<template>
  <div>
    <el-select
        v-model="selectVal"
        :disabled="disabled"
        clearable
        filterable
        placeholder="请选择结算方"
        style="width: 100%"
    >
      <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {getSettlementList} from "@/api/common";

export default {
  name: "settlement-select",
  props: {
    value: String,
    permissionId: {
      type: String,
      default: "758232741762187264",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      options: [],
      selectVal: this.value,
    };
  },
  watch: {
    value(newVal) {
      if (!newVal) {
        this.selectVal = "";
      } else {
        this.selectVal = newVal;
      }
    },
    selectVal(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    async getSettlementList() {
      const res = await getSettlementList(this.permissionId);
      if (res && res.code === 30 && res.result) {
        this.options = res.returnObject.list.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      }
    },
    getCurrentItem() {
      const selectVal = this.selectVal;
      if (!selectVal) {
        return {};
      }

      return this.options.filter(item => item.id === selectVal)[0];
    }
  },
  created() {
    this.getSettlementList();
  },
};
</script>

<style lang="less" scoped>
</style>
