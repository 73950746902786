<template>
  <div>
    <el-select
        v-model="selectVal"
        placeholder="请选择触发事件"
        style="width: 100%"
    >
      <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {getCouponEventList} from "@/api/common";

export default {
  name: "event-select",
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "请选择触发事件",
    },
  },
  data() {
    return {
      options: [],
      selectVal: this.value,
    };
  },
  watch: {
    value(newVal) {
      if (!newVal) {
        this.selectVal = "";
      } else {
        this.selectVal = newVal;
      }
    },
    selectVal(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    async getCouponEventList() {
      const res = await getCouponEventList();
      if (res && res.code === 30 && res.result) {
        this.options = res.returnObject.map((item) => {
          return {
            id: item.id,
            name: item.couponEventName,
            key: item.couponEventKey
          };
        });
      }
    },
  },
  created() {
    this.getCouponEventList();
  },
};
</script>

<style lang="less" scoped>
</style>
